import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "../css/SlideAuto.css";

// import required modules
import { Autoplay, Pagination, Navigation } from "swiper";


import img1 from '../images/1j.jpeg';
import img2 from '../images/5j.jpeg';
import img3 from '../images/3j.jpeg';
import img4 from '../images/4j.jpeg';


const SlideAuto = () => {
    let data = [
        {
            id: 1,
            imgSrc: img1,
        },
        {
            id: 2,
            imgSrc: img2,
        },

        {
            id: 3,
            imgSrc: img3,
        },
        {
            id: 4,
            imgSrc: img4,
        },



    ]
    return (
        <>

            <div className="slider mb-4 pb-4">
                <Swiper
                    slidesPerView={window.innerWidth >= 500 ? 3 : 1}
                    spaceBetween={20}
                    slidesPerGroup={1}
                    loop={true}
                    loopFillGroupWithBlank={true}


                    autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                    }}
                    pagination={{
                        clickable: true,
                    }}
                    navigation={true}
                    modules={[Autoplay, Pagination, Navigation]}
                    className="mySwiper"
                >
                    {data.map((item, index) => {
                        return (
                            <SwiperSlide>
                                <img src={item.imgSrc} />
                            </SwiperSlide>
                        )
                    })}
                </Swiper>
                </div>
        </>
    );
}

export default SlideAuto;