import restaurant from "./images/club.jpg";
import img_card from "./images/9.png";
import img_nasi from "./images/2aa.jpeg";
import img_bucatar from "./images/1a.jpeg";
import img_music from "./images/2j.jpeg";
import imgOmSuflet from "./images/nava2.png";
import imgOmSufletmb from "./images/nava2m.png";
import imgheader from "./images/happy3.jpg";
import imgheaderm from "./images/happy7.jpg";
import imgheadermiini from "./images/robloxh.jpg";
import imgheadermiinimb from "./images/robloxm.jpg";
import imgconfirmare from "./images/minioni.jpg";

{/*valeriacosarca@gmail.com*/}

const data = {
    introData: [{
        mire: "",
        mireasa: "",
        tata: "Andrey",
        mama: "Katy",
        copilul: "Andrey",
        familia: "fam. Gugoașa",
        title: "Invitatie Online",
        data: "8 Octombrie 2022",
        data_confirmare: "",
        savedata: "Vă invit!",
        ani: "",
        imgdesktop: imgheader,
        imgmobil: imgheaderm,
        email: "gugoasa.mirela.catalina@gmail.com", 
       tel: "+40756640759",
       phone: "tel:+40756640759",
       viber: "viber://chat?number=%2B34642647836",
       whatsapp: "https://wa.me/+40756640759",
       messenger: "https://www.messenger.com/t/InvitatiiWeb",
       tel1: "+40740907712",
       phone1: "tel:+40740907712",
       viber1: "viber://chat?number=%2BXX",
       whatsapp1: "https://wa.me/+40740907712",
       messenger1: "https://www.messenger.com/t/",
    }],
    cardData: [
        {
            id: 1,
            img: restaurant,
            title: "Locația Evenimentului",
            name: "Club Kids Planets",
            data: "8 octombrie 2022, sâmbătă, ora 16:00-19:30",
            adress: "Strada Pictor Octav Băncilă , nr. 25, Galați, Romania",
            harta: "https://goo.gl/maps/K1DZAeCTuqbhPzF86",
            iframe: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11196.740001696657!2d28.0117456!3d45.445928!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x101177047915670b!2sKids%20Planets!5e0!3m2!1sro!2s!4v1664181240443!5m2!1sro!2s" 
           
        }

    ],
    blogDataNoi: [
        {
            id: 1,
            img: img_card,
            title1: "Invitație!",
            title2: "TE INVIT LA ZIUA MEA!",
            message: "Pentru că împlinesc 12 ANI, te invit cu mare drag, să sărbătorim împreună, cu muzică, dans și multe suprize!",
        }

    ],
    blogDataNasii: [
        {
            id: 2,
            img: img_nasi,
            title1: "Familia GUGOAȘA",
            title2: "Salutare!",
            message: "Dacă ați ajuns aici înseamnă că ținem la voi și ne-am bucura enorm dacă veți fi alături de noi într-o zi atât de specială!",
        }
    ],
    blogDataBucatar: [
        {
            id: 2,
            img: img_bucatar,
            title1: "Vă invităm alături de cea mai bună bucătăreasă:",
            title2: "Natalia Căpătici Vizitiu",
            message: "Și să gustăm din bucatele delicioase a mânuților de aur!",
        }
    ],
    blogDataMusic: [
        {
            id: 2,
            img: img_music,
            title1: "Sub conducerea muzicală de:",
            title2: "Deejay Vibe",
            message: "Distracția și dansurile sunt asigurate până dimineață!",
        }
    ],
    blogDataOmSuflet: [
        {

            img: imgOmSuflet,
            img1: imgOmSufletmb,
            title: "Ne vom distra pe bune!",
            message: "Alături de cel mai bun DJ Vibe!",
            nasii: "",
            nasii_nume: "",
        }
    ],
    blogDataDrum: [
        {
            imgdesktop: imgheadermiini,
            imgmobil: imgheadermiinimb,
            title: "12 ANI",
            message: "LA MULȚI ANI!",
        }
    ],
    blogDataConfirmare: [
        {
            imgdesktop: imgconfirmare,
            imgmobil: imgconfirmare,
            title: "Nu uitați să luați cu voi un car de voie bună și opinci de dansat!!!",
            message: "P.S: Vom fi recunoscători pentru confirmarea prezenței DVS!",
        }
    ], 

}

export default data;